import { useMutation, useQuery } from '@tanstack/react-query';

import { API_URL } from '@/config';
import { get404Path, getBillingPath, getLoginPath, isGuestPath } from '@/utilities/paths';
import { redirectWithFlash } from '@/utilities/flash';

export type MutationPair<S, E = Error> =
  | { onSuccess?: (data: S | undefined) => void; onError?: (error: E) => void }
  | undefined;

const commonHeaders: HeadersInit = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const commonHeadersFile: HeadersInit = {
  Accept: 'application/json',
};

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    let cookieValue = parts.pop()?.split(';').shift();
    if (cookieValue) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
}

export class ResponseError extends Error {
  private response: Response;

  constructor(response: Response) {
    super(`HTTP error! status: ${response.statusText}`);
    this.name = 'ResponseError';
    this.response = response;
  }

  get status() {
    return this.response.status;
  }

  async getData() {
    return await this.response.json();
  }
}

export async function csrf() {
  await fetch(`${API_URL}/sanctum/csrf-cookie`, { credentials: 'include', headers: commonHeaders });
}

// Ping will not trigger a 401 and so can be checked both in and out of app without redirects
export async function ping() {
  const response = await fetch(`${API_URL}/api/ping`, { credentials: 'include', headers: commonHeaders });
  const data = await response.json();
  return data.isLoggedIn;
}

async function customFetch<T>(
  path: string,
  method: string,
  payload?: unknown,
  customHeaders: HeadersInit = {},
): Promise<T | undefined> {
  const isFile = payload instanceof FormData;

  // Pre-request
  const isStateChanging = ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase());
  let xsrfToken = null;

  if (isStateChanging) {
    await csrf();
    xsrfToken = getCookie('XSRF-TOKEN');
  }

  const headers: any = {
    ...(isFile ? commonHeadersFile : commonHeaders),
    ...(xsrfToken ? { 'X-XSRF-TOKEN': xsrfToken } : {}),
    ...customHeaders,
  };

  // Execute
  const response = await fetch(`${API_URL}/${path}`, {
    method,
    headers,
    credentials: 'include',
    ...(payload ? { body: isFile ? payload : JSON.stringify(payload) } : {}),
  });

  // Post-request
  if (!response.ok) {
    switch (response.status) {
      case 401:
        if (window.location.pathname !== getLoginPath()) {
          redirectWithFlash(getLoginPath(), 'Your session has expired', 'info');
        }
        return;
      case 403:
        redirectWithFlash(getBillingPath(), 'Please subscribe to access premium content', 'info');
        return;
      default:
        throw new ResponseError(response);
    }
  }

  // Return
  return (await response.json()) as T;
}

export async function login(email: string, password: string) {
  await customFetch<any>('login', 'POST', { email, password });
}

export async function forgot(email: string) {
  await customFetch<void>('forgot-password', 'POST', { email });
}

export async function reset(email: string, password: string, token: string) {
  await customFetch<void>('reset-password', 'POST', { email, password, password_confirmation: password, token });
}

export async function logout() {
  await customFetch('logout', 'POST', {});
  redirectWithFlash(getLoginPath(), 'You have been logged out', 'success');
}

export async function get<T>(path: string, customHeaders: HeadersInit = {}) {
  return await customFetch<T>(`api/${path}`, 'GET', undefined, customHeaders);
}

export async function put<T>(path: string, payload: unknown) {
  return await customFetch<T>(`api/${path}`, 'PUT', payload);
}

export async function patch<T>(path: string, payload: unknown) {
  return customFetch<T>(`api/${path}`, 'PATCH', payload);
}

export async function postFile<T>(path: string, payload: FormData) {
  return await customFetch<T>(`api/${path}`, 'POST', payload);
}

export async function post<T>(path: string, payload: unknown) {
  return await customFetch<T>(`api/${path}`, 'POST', payload);
}

export async function destroy<T>(path: string) {
  return await customFetch<T>(`api/${path}`, 'DELETE');
}

// =====================================================================================================================
// Queries
// =====================================================================================================================

// ---------------------------------------------------------------------------------------------------------------------
// Get Me
// ---------------------------------------------------------------------------------------------------------------------
export interface MeResponse {
  name: string;
}

export function useQueryMe() {
  return useQuery({
    queryKey: ['me'],
    queryFn: () => get<MeResponse>('dashboard/me'),
  });
}
